html,body,div,span,
h1,h2,h3,h4,h5,h6,
p,blockquote,q,em,img,small,strong,
dl,dt,dd,ol,ul,li,fieldset,form,label,legend{border:0;outline:0;margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent}
body{line-height:1}
ol,ul{list-style:none}
:focus{outline:0}
input,textarea{margin:0;outline:0;}
textarea{overflow:auto; resize:none;}
table{border-collapse:collapse;border-spacing:0}
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
/* Default Font Styles
______________________*/
body, input, select, textarea, p, a, b{
  font-family: 'Montserrat', sans-serif;
    color:#000;
    line-height:1.4;
}
#root{
    background: #fff;
}
body{
    background: white !important;
    display: inherit;
    min-height: 100vh;
    flex-direction: column;
}

*,
*:after,
*:before{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.main_body {
    flex: 1;
}

/* Links
______________________*/
.user_login_padd_top{
    padding-top:46px !important;
}
a{ }
a:hover {
    -webkit-transition:0.4s;
    -moz-transition:0.4s;
    -ms-transition:0.4s;
    -o-transition:0.4s;
    transition:0.4s;
}
/* p, blockquote, address
______________________*/
p{
    font-size: 15px;
    margin-bottom:15px;
}
a:hover, a:focus{
    text-decoration: none;
}
/* Layout
______________________*/

/*autocomplete start*/
.button-loadings {
  background-color: #008cba; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.button-loadings:disabled {
  opacity: 0.5;
}
.keep_me_login label{
font-size: 26px;
}
.keep_me_login input{
    margin: 0 0 0 9px;
    width: 24px;
    height: 19px;
}
#locationField, #controls {
        position: relative;
        width: 480px;
      }
      #autocomplete {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 99%;
      }
      .label {
        text-align: right;
        font-weight: bold;
        width: 100px;
        color: #303030;
        font-family: "Roboto";
      }
      #address {
        border: 1px solid #000090;
        background-color: #f0f9ff;
        width: 480px;
        padding-right: 2px;
      }
      #address td {
        font-size: 10pt;
      }
      .field {
        width: 99%;
      }
      .slimField {
        width: 80px;
      }
      .wideField {
        width: 200px;
      }
      #locationField {
        height: 20px;
        margin-bottom: 2px;
      }



/*autocomplete ends*/
.detailErrMsg{
    color: red !important;
    font-size: 11px !important;
    text-align: left !important;
    top: -32px !important;
}
.delivery_address {
    position: relative;
    padding: 0 4px;
    margin: 28px 0 0;
    float: left;
    width: 50%;
}
.react-datepicker-wrapper {
    display: block !important;
    padding: 0;
    border: 0;
}
.delivery_address label {
    position: absolute;
    left: 5px;
    top: -21px;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
}
.error_box {
    color: red !important;
    font-size: 12px !important;
    margin: -10px 0 14px !important;
        text-align: left;
    padding: 0 0 0 21px;
}
.delivery_address input,.delivery_address textarea,.delivery_address select {
    border-radius: 2px;
    font-size: 13px;
    width: 100%;
    border: 1px solid #cccccccf;
}
.delivery_address input,.delivery_address select {
    height: 40px;
    padding: 4px 15px 0;
    margin: 0 0 9px;
}
.delivery_address select option{
    background: #fff;
    color: #000;
}
.delivery_address textarea{
    height: 80px;
    padding: 19px 20px 0;
}
.rider_direction{
    width: 100%;
}
.row{
    margin: 0;
}
.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}
.main_body {
    background: #fff !important;
    /*height: 100vh;*/
}
html{
    background:#f8f8f8;
}
.login_screen {
    max-width: 426px;
    margin: 0 auto 20px;
    text-align: center;
    padding: 0;
    background: #ffffff;
    height: auto;

}
.logo{/* background-image: linear-gradient(17deg, #e1355e, #ec844d); */padding: 5px 0;border-radius: 0 0 0 65px;margin-bottom: -1px;text-align: center;}
.logo img {
    width: 44%;
    margin: 72px auto 14px;
    display: block;
}
.user_login{
    padding: 11px 27px 100px;
}
.form_box {
    position: relative;
}
html{
    background: #fff;
}
.form_box input {
    width: 100%;
    margin: 0 0 15px;
    border-radius: 35px;
    padding: 0 18px 0 55px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    background: #fff;
    box-shadow: 0 0 7px 0 #e0d7d7;
    -webkit-box-shadow: 1px 5px 18px rgba(13, 90, 160, 0.06);
    border: 1px solid #f3f0f0;
}
.order_lists .select_items_pdt h3 {
    margin-bottom: 12px;
}
.user_login p {
    color: #2b8aa6;
    margin: 5px 0 3px;
    font-weight: 500;
    font-size: 19px;
}
.user_login p a {
    color: #049dc9;
    font-weight: bold;
    font-size: 19px;
}
.form_box button {
    width: 46%;
    display: inline-block;
    border-radius: 35px;
    padding: 10px 4px;
    color: #fff;
    cursor: pointer;
    margin: 10px 3px 0;
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #238ead;
    display: inline-block;
    box-shadow: 0px 17px 10px -10px rgb(216, 216, 216);
}

.form_box .reset_btn{
    background: transparent;
    color: #1ab394;
}
.form_box button:hover, .form_box button {
    background-image: linear-gradient(-90deg, #2b8aa6, #049dc9);
}
.form_box path{
    fill: #293846;
}
.form_box svg {
    position: absolute;
    left: 22px;
    top: 12px;
}
.loader {
    position: fixed;
    top: 35%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background:#f5f5f5;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 999999;
    text-align: center;
}
.loader img {
margin: 17% auto 0;
    cursor: pointer;
    vertical-align: middle;
    display: block;
    width: auto;
    object-fit: cover;
    height: auto;

}

.loader img  {
    animation-name: slideDown;
    -webkit-animation-name: slideDown;

    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
animation-iteration-count: 1;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible !important;
}

@keyframes slideDown {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}

@-webkit-keyframes slideDown {
      from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}



.Select_language .reset_btn:hover,.Select_language a,
.Select_language .reset_btn:hover{
    background: #1894b8;
    color: #fff;
}
button:focus{
    outline: 0;
}
.Select_language > a {
    width: 45%;
    display: inline-block;
    border-radius: 35px;
    padding: 12px 1px;
    color: #fff;
    margin: 18px 7px 0;
    box-shadow: 0 0 7px 0 #25242480;
    font-size: 19px;
    font-weight: 500;
    text-transform: capitalize;
    font-weight: 600;
    background: #98bf00;
    text-align: center;
}
.Select_language .reset_btn {
    background: #15597a;
    color: #ffffff;
}
.Select_language h3 {
    margin: 6px 0 12px;
    font-size: 23px;
    font-weight: 600;
    color: #333;
    text-transform: unset;
}
.Select_language{
    padding: 11px 14px 100px;
}
.Request_door a{
    width: 94%;
    font-size: 16px;
}
.welcome_user h4{
font-size: 18px;
    margin: 0 0 10px;
    color: #98bf00;
}

/*checkbox*/

.color_select label{display:inline-block;margin-bottom:0;font-size: 14px;margin-left: 6px;}
.color_select input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
.color_select input{overflow:visible;}
.color_select input[type=checkbox]{box-sizing:border-box;padding:0;}
.custom-control{
    position: relative;
    display: inline-block;
    padding-left: 15px;
    margin: 21px 10px 5px;
    font-size: 14px;
    font-weight: 500;
}
.custom-control-input{position:absolute;z-index:-1;opacity:0;}
.custom-control-input:checked~.custom-control-label::before{color:#fff;background-color:#007bff;}
.custom-control-input:focus~.custom-control-label::before{box-shadow:0 0 0 1px #fff,0 0 0 .2rem rgba(0,123,255,.25);}
.custom-control-input:active~.custom-control-label::before{color:#fff;background-color:#b3d7ff;}
.custom-control-input:disabled~.custom-control-label{color:#6c757d;}
.custom-control-input:disabled~.custom-control-label::before{background-color:#e9ecef;}
.custom-control-label{
    margin-bottom: 0;
    padding: 0 0 0 16px;
    vertical-align: middle;
    margin-top: 0px;
}
.custom-control-label::before{position:absolute;top: -2px;left: -10px;display:block;width: 23px;height: 23px;pointer-events:none;content:"";-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:#bfc2c5;}
.custom-control-label::after{position:absolute;top: -2px;left: -10px;display:block;width: 23px;height: 23px;content:"";background-repeat:no-repeat;background-position:center center;background-size:50% 50%;}
.custom-checkbox .custom-control-label::before{border-radius:2px;}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before{background-color:#007bff;}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after{background-image:url("../images/checkmarks.svg");}
.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(0,123,255,.5);}
.bold-colr {
    font-weight: 500 !important;
    color: #000;
    padding: 0 0 0 7px;
    font-size: 14px;
    vertical-align: middle;
    margin: -4px 0 0;
}
.variation_table .custom-control-label::before {background-color: #afb2b5;}
.custom-radio input[type=radio]{box-sizing:border-box;padding:0;}
.custom-radio .custom-control-label::before{border-radius:50%;}
.custom-radio .custom-control-input:checked~.custom-control-label::before{background-color: #7cad04;}
.custom-radio .custom-control-input:checked~.custom-control-label::after{background-image:url("../images/radio_btn.svg");}
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before{background-color:rgba(0,123,255,.5);}
.variation_table .custom-control {margin: 0 auto;padding: 0 8px;}

.Grocery{
    position: relative;
}
.Grocery a {
    width: 100%;
}
.Grocery .veg_fru{
    background:#8BC34A;
}




.main_body_n {
    background: #fff;
    /*height: 100vh;*/
}

.user_login_n h3{font-weight: 800;margin-bottom:15px;}
.login_screen ,.logo_screen_n {
    max-width: 426px;
    margin: 0 auto 0px;
    text-align: center;
    padding: 42px 0 0;
    /*padding: 42px 0 115px;*/
    background: #ffffff;
    height: auto;
    position: relative;
}
.hamburger{
    position: fixed;
}

.main-page-login{
      background: #ede6f5;
      height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 0 0px;
    background-position: bottom;
}
.product_contents_n h4 {
    margin: 0 0 6px;
    font-size: 11px;
    font-weight: bold;
    color: #4a4a4a;
    position: absolute;
    top: -4px;
    line-height: 1.2;
}
.logo_screen_n .user_login_n p {
    margin: 0px 0 3px;
    font-size: 12px;
}
.quantity_box .value_box {
    text-align: center;
    width: 34px;
    height: 25px;
    margin: -1px -6px;
    border-left: none;
    border-right: none;
    border: 1px solid #dcd8d8;
    vertical-align: middle;
    padding: 0;
    font-size: 12px;
}
.quantity_box .minus_plus {
    background: #fff;
    color: #238faf;
    border: 1px solid #dcd8d8;
    height: 29px !important;
    vertical-align: middle;
    width: 25px !important;
}
.quantity_box {
    margin: 3px 0 0;
}
.product_img_n img {
    width: 60px !important;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    padding: 5px;

}
.splash_getstart{
    padding-top: 190px;
}
#uder_eng_language .splash_getstart button{
    font-size: 18px;
    width: auto;
    padding: 14px 68px;
}
.product_price_n p {
    font-size: 16px !important;
    font-weight: 600;
    color: #125778;
}
.product_contents_n {
    text-align: left;
    flex: 0 0 52.666667%;
    max-width: 60.666667%;
    padding-right: 0;
    padding-top: 26px;
}
/*.product_price_n .value_div{
    display: inline;
    color: #238faf;
    border: 1px solid #dcd8d8;
    vertical-align: middle;
    width: 32px;
    border-left: none;
    border-right: none;
    padding: 4px 10px;
}*/
.product_layout_n {
    border: 1px solid #3333331c;
    padding: 13px 0 11px;
    margin: 6px 0;
}
.product_img_n {
    flex: 0 0 24%;
    max-width: 20%;
    padding: 0;

}
.qty_select_list{
    padding-bottom: 50px;
}
.product_price_n {
    flex: 0 0 27%;
    max-width: 27%;
}
.bottom_bar_n {
    text-align: center;
    background: #fff;
    position: fixed;
    bottom:66px;
    right: 0;
    box-shadow: -1px -3px 5px 0 #0000000f;
    left: 0;
    z-index: 99;
    padding: 7px 0 4px;
}
.bottom_bar_n ul li img {
    width: 21px;
}
.bottom_bar_n ul li b {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    margin-top: 0px;
    text-transform: capitalize;
}
.bottom_bar_n ul li {
    text-align: center;
    display: inline-block;
    width: 49%;
}
.bottom_bar_n ul li b span{
    display: inline-block;
    float: right;
}
.bottom_bar_n ul li b{
  color: #333;
}

/*.Grocery{
 background:#8BC34A;
}*/
.Grocery a img{
    border-radius: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 58px;
    height: 58px;
    object-fit: cover;
}
#Grocery a {
    width: 100%;
}
#Grocery .veg_fru{
    background:#8BC34A;
}
#Grocery{
position: relative;
}
#Grocery a img{
    border-radius: 100%;
    position: absolute;
    right: -2px;
    bottom: 2px;
    width: 58px;
    height: 58px;
    object-fit: cover;
}
#GroceryEng a {
    width: 100%;
}
#GroceryEng .veg_fru{
    background:#8BC34A;
}
#GroceryEng{
position: relative;
}
#GroceryEng a img{
    border-radius: 100%;
    position: absolute;
    left: 0px;
    top: 10px;
    width: 58px;
    height: 58px;
    object-fit: cover;
}
.Select_language #Grocery a{
        padding: 19px 1px 16px 37px;
}
.Fruits{
    position: relative;
}
.Fruits a {
    width: 100%;
}
.Fruits .veg_fru{
}
.Fruits a{
    background: #8BC34A;
}
.Fruits a img{
    border-radius: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 58px;
    height: 58px;
    object-fit: cover;
}
.Select_language .Fruits a{
        padding: 19px 1px 16px 37px;
}
.middle_logo {
    text-align: center;
}
.middle_logo img{
    width: 172px;
    margin: 0 auto 12px;
}
#login_items a button{
    font-size: 29px;
    width: 100%;
}
#request_door a {
    font-size: 21px;
}
.product_layout {
    border: 1px solid #a2a2a226;
    padding: 0 0 7px;
    margin: 0 0 11px;
}
.product_img {
    max-width: 22%;
    width: 22%;
    padding: 4px 0 3px 11px;
    display: inline-block;
    float: left;
}
#pos_listgs .product_layout_n a{
    width: 78%;
    display: inline-block;
}
.product_img img {
    width: 66px;
    border-radius: 100%;
    border: 1px solid #ccc;
    height: 66px;
    object-fit: cover;
}
.product_contents {
    max-width: 54%;
    width: 54%;
    padding: 2px 0 0 18px;
    text-align: left;
}
.product_price {
    max-width: 45%;
    width: 45%;
    padding: 24px 0 0;
}
#pos_listgs #product_row br{
    display: none;
}
.product_price p {
    font-size: 15px;
    font-weight: bold;
    color: #1794b8;
}
.select_items_pdt  h3{
    margin-bottom: 26px;
}
.quantity_box .minus_plus,.quantity_box .value_box{
    margin-bottom: 0;
    font-size: 19px;
}
.quantity_box .minus_plus{
    background: #fff;
    color: #238faf;
    border: 1px solid #dcd8d8;
    height: 29px;
    vertical-align: middle;
    width: 25px;
}
.quantity_box .value_box{text-align: center;width: 41px;height: 32px;margin: -1px -6px;border-left: none;border-right: none;border: 1px solid #dcd8d8;vertical-align: middle;padding: 0;font-size: 15px;}
.product_contents h4 {
    margin: 0 0 8px;
    font-size: 15px;
    font-weight: bold;
    color: #4a4a4a;
}
.quantity_box{
    margin: 3px 0 0;
}
/*.product_layout:last-child{
    border-bottom: none;
}*/
.total_amount {
    text-align: left;
    margin: 23px 0 5px;
}
.total_amount b {
    font-size: 21px;
}
.total_amount b span {
    float: right;
    color: #98bf00;
}
.urdu-text input, .urdu-text label,.urdu-text textarea,.urdu-text ul li{
    text-align: right;
}
.urdu_fontfamily,.urdu-text , .urdu-text b , .urdu-text p ,  .urdu-text input ,  .urdu-text label ,.urdu-text h1, .urdu-text h2, .urdu-text h3, .urdu-text h4, .urdu-text h4, .urdu-text h6 , .urdu-text ul li , .urdu-text a {
   font-family: JameelNooriNastaleeq !important;
}
.hamburger {
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 999;
}
.hamburger i {
  font-size: 25px;
  color: white;
}
.nav-offcanvas {
  width: 242px;
  height: 100em !important;
    background-image: linear-gradient(-37deg, #125778, #1d7dab);
  position: fixed;
  overflow-x: auto;
  z-index: 2000;
  padding: 50px 0;
  top: 0;
  left: -300px;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.nav-offcanvas .close {
  position: absolute;
  right: 14px;
  top: 15px;
  outline: 0;
  opacity: 1;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: 0.3s ease;
}
.nav-offcanvas .close i {
  font-size: 20px;
  color: white;
  text-shadow: none;
}
.nav-offcanvas .close:hover {
  transform: rotate(90deg);
}
.nav-offcanvas-menu ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.nav-offcanvas-menu ul li a {
  color: white;
  font-size: 23px;
  font-weight: 500;
  padding: 9px 26px 7px;
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
  border-bottom: 1px solid #ffffff1c;
}
.nav-offcanvas-menu ul li a:hover {
    transform: none;
    background: #185571;
    color: #fff;
}
.nav-offcanvas.open {
  left: 0;
}
.offcanvas-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.62);
  z-index: 1999;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  visibility: hidden;
  opacity: 0;
}
.offcanvas-overlay.on {
  opacity: 1;
  visibility: visible;
}
.close img{
    width: 20px;
}
.hamburger img{
    width: 25px;
}
.bottom_bar {
    text-align: center;
    background-image: linear-gradient(-90deg, #2d8aa5, #029ecb);
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 99;
    padding: 10px 0 6px;
}
.bottom_bar ul li {
    text-align: center;
    display: inline-block;
    width: 24%;
}
.bottom_bar ul li b {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-top: 5px;
    text-transform: capitalize;
}
.bottom_bar ul li img{
    margin: 0 auto;
    width: 27px;
    display: block;
}
.last_step {
    text-align: center;
    display: none;
    padding: 107px 0 0;
}
.last_step img {
    cursor: pointer;
    width: 100px;
}
.last_step b {
    display: block;
    margin: 12px 0 0;
    font-size: 24px;
    color: #10a711;
}
.contact_form {
    background: #fff;
    box-shadow: 1px 12px 31px rgba(13, 90, 160, 0.18);
    padding: 17px 21px;
    margin-top: 9px;
}
.contact_form .form_row{
    text-align: left;
}
.form_row label {
    display: block;
    font-size: 21px;
    font-weight: 500;
    margin: 0 0 6px;
}
.form_row input, .form_row textarea {
    width: 100%;
    padding: 0 15px;
    margin: 0 0 15px;
    border-radius: 4px;
    border: 1px solid #eb79504a;
    height: 40px;
    font-size: 22px;
}
.form_row textarea {
    height: 70px;
    padding-top: 10px;
}
.form_row button {
    background: #e65757;
    color: #fff;
    padding: 13px 17px;
    display: inline-block;
    margin: 0;
    font-size: 30px;
    border-radius: 35px;
    font-weight: 400;
    width: 100%;
    border: none;
}
.checkout_urdr .product_contents h4 {
    font-size: 23px;
}
.checkout_urdr .total_amount b {
    font-size: 34px;
}
.checkout_urdr #request_door a {
    font-size: 40px;
    padding: 12px 0 18px;
}
.urdu-text .bottom_bar ul li b {
    font-size: 23px;
}
.urdu-text .bottom_bar {
    padding: 10px 0;
}
.urdu-text .form_row label {
    font-size: 28px;
    margin: 0 0 9px;
}
.urdu-text .form_row button {
    padding: 26px 17px 16px;
    font-size: 38px;
}
.language_urdu #uder_eng_language  .Select_language a{
        padding:16px 0 10px;
}
.items_select_urdu .Select_language .items_type_btns a {
    padding: 18px 0 13px 27px;
    font-size: 34px;
}
.items_select_urdu .items_type_btns a img {
    left: 2px;
    top: 17px;
    width: 80px;
    height: 80px;
}
.urdu-text .form_box input {
    padding: 10px 18px 5px 55px;
    height: 52px;
    font-size: 22px;
}
.urdu-text .form_box svg {
    top: 16px;
}
.urdu-text  .user_login #login_items  p {
    font-size: 40px;
}
.urdu-text #login_items a button {
    font-size: 42px;
    padding:22px 0 7px;
}
.urdu-text .user_login p a {
    font-size: 32px;
}
.urdu-text #request_door a {
    font-size: 28px;
    padding: 22px 0 14px !important;
}
.urdu-text .custom-control-label::after, .urdu-text .custom-control-label::before{
    top: 2px;
}
.urdu-text .custom-control {
    margin: 15px 15px 5px;
    font-size: 27px;
}
.urdu-text  .last_step b {
    font-size: 48px;
}
.contact_info ul{
    text-align: left;
}
.contact_info ul li a {
    background: transparent;
    box-shadow: none;
    color: #000;
    padding: 0;
    font-size: 14px;
    margin: 0;
    width: auto;
    border-radius: 0;
    text-transform: inherit;
}
.contact_info h4 {
    margin: 0 0 7px;
    font-weight: bold;
    color:#0d99c2;
    font-size: 20px;
}
.contact_info h3 {
    font-size: 20px;
    font-weight: 600;
}
.contact_info ul li {
    margin: 0 0 9px;
    position: relative;
    padding: 0 0 0 30px;
    font-weight: 500;
}
.contact_info ul li i {
    position: absolute;
    left: 3px;
    top: 4px;
}
/* record button */
#recbtn {
	width: 35px;
	height: 35px;
	font-size: 0;
	width: auto;
    padding: 0 17px;
    background: #b10d0d;
	border: 0;
	border-radius: 35px;
	margin: 0 24px;
	outline: none;
}
.recording {
    display: inline-block;
    background: #e90909;
    height: 96px;
    width: 96px;
    border-radius: 100%;
}
.Rec{
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
@keyframes pulse{
	0%{
		box-shadow: 0px 0px 5px 0px rgba(230, 18, 18, 0.562);
	}
	65%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
	}
	90%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
	}
}
.contact_info .form_row label {
    text-align: left;
}
.contact_info  .select_items_pdt img{
        width: 178px;
    margin: -20px 0 9px;
}
.order_listing {
    background: #f5f5f5ba;
    padding: 0;
    border-left: 1px solid #cccccc8a;
    border-top: 3px solid #e44b59;
    border-right: 1px solid #cccccc8a;
    border-radius: 3px;
    margin: 0 0 10px;
}
.order_listing b {
    font-size: 12px;
    display: block;
    text-align: left;
    border-bottom: 1px solid #cccccc5e;
    padding: 8px 16px;
    font-weight: 600;
}
.order_listing b span {
    float: none;
    font-weight: 400;
    display: block;
    margin: 4px 0 0;
    font-size: 11px;
}
#order_lists_load .Select_language {
    padding: 0 0 40px;
}
#invoice_listing_p .select_items_pdt {
    background-image: linear-gradient(
-225deg
 ,#2f80d4 35%,#337fce 100%);
    padding: 14px 16px 27px;
}
#order_lists_load .select_items_pdt h3 {
    /* background-image: linear-gradient(-90deg, #e4495a, #ec844d); */

    /* background-image: linear-gradient(-90deg, #e4495a, #ec844d); */
    color: #1794b8;
    padding: 12px 0 14px;
    margin-bottom: 24px;
    text-align: center;

}
#order_lists_load  .user_login p {
    font-size: 12px;
}
#order_lists_load .product_contents {
    padding: 13px 0 0 18px;
    float: left;
}
#order_lists_load .product_price p {
    font-size: 12px;
    margin: 1px 0;
}
#order_lists_load .product_layout {
    display: inline-block;
    width: 48%;
    background: #f5f5f5;
    margin: 2px 0px 2px 10px;
    padding: 8px 2px 8px 8px;
    border-radius: 2px;
}
#order_lists_load .product_price {
    padding: 21px 13px 0 0;
    float: left;
    text-align: right;
}
.login_screen {
    width: 100%;
    height: auto;
    /*background: url(../images/bgimage.jpg);*/
    background-position: center center;
    background-size: 149%;
    animation: animatedBackground 500s linear infinite;
    background-repeat: repeat-x;
}

@keyframes animatedBackground {
  from {
    background-position: -10000px 0;
  }
/*use negative width if you want it to flow right to left else and positive for left to right*/
  to {
    background-position: 0 0;
  }
}
.form_box_urdu  input, .form_box_urdu textarea, .form_box_urdu select{
    text-align: right;
}
.form_box_urdu label {
    left: auto;
    right: 23px;
}
.urdu_nav ul {
    text-align: right;
}
.urdr_page .form_box input {
    padding: 0 51px 0 19px !important;
    text-align: right;
    vertical-align: middle;
    height: 52px;
    line-height: 52px;
}
.swal-text:first-child {
    margin-top: 35px;
}
.swal-footer {
    text-align: center;
    padding: 13px 16px 36px;
}
.swal-button ,.swal-button:hover{
    background-color: #98bf00 !important;
    box-shadow: none;
    outline: 0;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 1px;
}
.urdr_page .form_box .error_box{
    padding: 0 21px 0 0;
    text-align: right;
}
.urdr_page .form_box svg {
    left: auto;
    right: 22px;
}
.search_data {
        padding: 9px 4px 0;
}
.search_data .ui{
    width: 100%;
}
.search_data input{
    font-size: 13px;
}
.urdu-text .radio_urdu  .custom-control-label::after,.urdu-text .radio_urdu  .custom-control-label::before {
    top: 2px;
    left: auto;
    right: -29px;
}
.urdu-text .radio_urdu  .custom-control {
    margin: 15px 15px 5px 0;
}
.cart-page{
    margin-top: 26px;
}
.search_porduct input {
    width: 100%;
    position: absolute;
    top: -29px;
    right: 0;
    height: 32px;
    font-size: 14px;
}
.total_btm-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 32%;
    padding: 0 0 0 17px;
    border-top: 1px solid #ccc;
    background-image: linear-gradient(-90deg, #2d8aa5, #029ecb);
    margin: 0 auto;
    width: 100%;
}
.total_btm-bar b{
  font-size: 28px;
    margin: 6px 4px 0 0;
    color: #ffffff;
    display: inline-block;
}
.total_btm-bar span{
      vertical-align: middle;
    font-size: 19px;
    margin: -6px 38px 0 0;
    display: inline-block;
    color: #fff;
}
.total_btm-bar .total_amount_box button {
    background: #125778;
    padding: 12px 3px 12px 13px;
    border: none;
    width: 33%;
    float: right;
    vertical-align: middle;
    margin: 0px 0 0;
    color: #fff;
    border-radius: 35px 0 0 33px;
    font-size: 20px;
}
.total_btm-bar .total_amount_box  button  i{
  font-size: 17px;
}
/*category slider*/

.cata-sub-slider {
    margin: 0 auto 0 14px;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
}
.cata-sub-slider {
        background: #fff;
    padding: 11px 2px;
    margin: 20px auto 0;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    /* border: 1px solid #d2edf8; */
    box-shadow: 1px 3px 11px rgba(4, 83, 140, 0.1);
    width:84%;
}
.cata-sub-slider li a{
      display: inline-block;
    background: #d2edf8;
    color: #000 !important;
    padding: 3px 9px 2px;
    border-radius: 35px;
    font-size: 13px;
    cursor: pointer;
    box-shadow: 0 0 15px 0 #d2edf8;
}
.cata-sub-nav::-webkit-scrollbar ,.cata-sub-slider::-webkit-scrollbar{
    display: none;
}
.cata-sub-slider ul {
    margin: 0 0 0 -1px;
    display: flex;
    -webkit-padding-start: 0px;
}
.cata-sub-slider  li {
    display: inline-table;
    margin: 0 10px;
    font-size: 16px;
}
.cata-sub-slider .arrow {
    background: #255791;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 39px;
    padding: 2px 0 0;
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    line-height: 0;
}
.all_category_tabs .cata-sub-slider li:nth-child(2) {
    margin-left: 4px;
}
.cata-sub-slider .arrow  i{
    color: #fff;
    font-size: 13px;
}
.cata-sub-slider .nav-prev- {
    left: 6px;
}
.cata-sub-slider .nav-next-,.cata-sub-slider .nav-prev-  {
    top: 66px;
}
.cata-sub-slider .nav-next-{
    right: 4px;
}
.invoice_table table tr td, .invoice_table table tr th {
    padding: 5px 6px;
    font-size: 14px;
        color: #9a9a9a;
}
.invoice_table {
    padding: 0 19px;
}
.total_invoice_bln {
    text-align: right;
    padding: 6px 6px;
    background: #f9f8f8;
    margin: 0 ;
    border: 1px solid #cccccc73;
    border-top: none;
}
.total_invoice_bln b {
    font-size: 23px;
    color: #1794b8;
}
.total_invoice_bln b span {
    color: #333;
    font-size: 20px;
}
.invoice_table table{
    margin-bottom: 0;
}
.invoice_table table th {
    background: transparent;
    color:#3c3c3c !important;
}
.invoice_table table tr:nth-child(even){
    background: #e8f2f7;
}
.total-amount {
    padding: 9px 0;
    background: #fff;
    text-align: center;
}
.total_btm-bar .total-amount button {
    background: #17a2b8;
    border: none;
    color: #fff;
    margin: 0 4px;
    font-size: 12px;
    padding: 7px 5px;
    width: 10%;
    border-radius: 2px;
    font-weight: bold;
}
.total_btm-bar .total-amount .save-print {
    background-image: linear-gradient(-90deg, #2d8aa5, #2c8aa5);
}
.total_btm-bar .total-amount .eamil-print {
    background: #33b953;
}
.product_price b{
    font-size: 14px;
}
.total_btm-bar button i{
    position: absolute;
    right: 20px;
    top: 18px;
}
.cata-sub-slider li a.active{
   background: #1794b8;
   color: #fff !important;
}
.approved {
    text-align: right;
    padding: 9px 7px;
}
.approved label{
    color: #333;
    font-weight: 500;
    vertical-align: middle;
    font-size: 18px;
}
.approved input{
    vertical-align: middle;
    margin: -2px 3px 0;
    width: 15px;
    height: 15px;
}
.request_door_click span {
    font-size: 12px;
    margin: -2px 38px 0 0;
}
.request_door_click i {
    position: static !important;
}
.loader_on_listing{
    text-align: center;
}
#login_section {
    background: url('../images/backgruond-image.jpg') !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    height: 100vh;
    padding-top: 80px;
}
input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important;
    -webkit-text-fill-background-color: #000 !important;
}
#login_section .login_screen, #login_section .logo_screen_n {
    margin: 0 auto 0px;
    padding: 0;
    border-radius: 6px;
        max-width: 361px;
}
#login_section .logo {
    border-radius: 7px;
}
#login_section .logo img {
    margin: 31px auto 14px;
}
#login_section .user_login {
    padding: 11px 27px 46px;
}
.total-amount button span{
    margin: -2px 0 0 0;
}

.main_row {
    display: flex;
    padding: 0px 20px 10px;
}
.col-8- {
    width: 100%;
}
.invoice-left-right ul {
    padding: 0;
    margin: 0;
}
.invoice-left-right ul li, .bg-right-inner ul li {
    list-style: none;
    color: #fff !important;
    font-family: 'Open Sans', sans-serif;
    padding: 1px 0 0;
    font-size: 13px;
}
.invoice-left-right ul li {
    color: #000 !important;
}
.invoice-left-right ul li span {
    font-weight: 600;
    /* width: 60px !important; */
}
.invoice-left-right ul li span, .bg-right-inner ul li span {
    /* width: 104px; */
    display: inline-block;
}
.invoice-space span {
    width: 80px;
}
.invoice-left-right ul li b, .bg-right-inner ul li b {
    padding-right: 7px;
}
.col-4- {
    width: 50%;
}
.imagge {
    text-align: left;
}
.imagge ul li span{
        width: 60px;
}
.angle_back{
    float: right;
    padding: 0 12px 0;
    margin: -4px 21px 0 0;
    border-radius: 2px;
}
#invoice_listing_p {
/*    background-image: linear-gradient(
-225deg
 ,#353535 35%,#3d3d3d 100%) !important;*/
    height: 100vh;
    margin: 0;
}
#invoice_listing_p .select_items_pdt h3{
    margin: 0px;
    font-size: 19px;
    color: #fff;
    padding: 0;
}
#invoice_header .hamburger {
      top: 24px;
    left: 31px;
}
#invoice_listing_p .main_row {
    display: inherit;
    padding: 10px 18px;
    background: #fff;
    border-bottom: 1px solid #cccccc7a;
}
#invoice_listing_p .invoice-space span ,#invoice_listing_p .invoice-left-right ul li {
    color: #3c3c3c;
}
#invoice_listing_p .invoice_table {
    background: #fff;
    padding: 10px 17px;
    max-height: 410px;
    overflow-y: auto;
    min-height: auto;
}
#invoice_listing_p .angle_back {
    margin: 0px 0 0 0;
    background: transparent;
    color: #fff;
    float: left;
    font-size: 37px;
    position: absolute;
    left: 8px;
    top: -3px;
}
#invoice_listing_p #order_lists_load {
    margin: 0;
}
#invoice_listing_ftr .total-amount {
    background-image: linear-gradient(#7ec34f, #7ec34f, #76c045) !important;
}
#invoice_listing_ftr button {
    background: none;
    padding: 0;
}
#invoice_listing_ftr button img {
    display: block;
    margin: 0 auto 6px;
    width: 37px;
}
#invoice_listing_ftr button span {
    margin: 0;
    font-size: 14px;
}
#invoice_listing_ftr{
    border: none;
}


@media(max-width: 1024px){
    .container{
        width: 100%;
    }
    .logo img {
        width: 24%;
    }
    .product_listing{
        padding-bottom: 549px;
    }
    .login_screen, .logo_screen_n {
        max-width: 100%;
        margin: 0 auto 118px;
    }
    .product_layout_n {
        float: left;
        width: 48%;
        margin: 4px 7px;
        height: 93px;
        background: #f5f5f5;
    }
    .total_btm-bar{
        width: 100%;
    }
    .search_porduct{
        flex: 100%;
        max-width: 100%;
    }
    .search_porduct input {
        width: 84%;
        top: -28px;
        right: 17px;
    }
    .login_screen {
        width: 50%;
    }
    .total_btm-bar .total-amount button {
        width: 17%;
    }

}



@media(max-width: 767px){
    .container{
        width: auto;
    }
    .cata-sub-slider li {
    margin: 0 2px;
}
.cata-sub-slider li a {
    padding: 3px 5px 2px;
    font-size: 10px;
}
    .angle_back {
        float: right;
        padding: 0 12px 0;
        margin: -4px 0px 0 0;
        position: absolute;
        right: 12px;
    }
    .main_row {
        width: 100%;
    }
    .col-4-,.col-8-{
            background: #f5f5f57d;
    padding: 7px;
    border: 1px solid #cccccc61;
    margin: 0 0 9px;
    }
    .main_row {
    display: inline-block;
    padding: 0px 20px;
}
.invoice-space span {
    width: 78px;
}
.col-4- {
    width: 100%;
}
.invoice-space span {
    width: 78px;
}
.imagge ul li span {
    width: 78px;
}
.invoice-left-right ul li, .bg-right-inner ul li {
    padding: 1px 0 6px;
}
    .total_btm-bar .total-amount button {
        width: 30%;
    }
    .form_box button {
        width: 64%;
    }
    .login_screen {
        width: 94%;
    }
    #order_lists_load .product_layout {
        width: 94%;
    }
    .search_porduct input {
        width: 79%;
        right: 7px;
    }
    .logo img {
        width: 44%;
    }
    .product_listing{
        padding-bottom: 0;
    }
    .product_layout_n {
        height: auto;
    }
    .hamburger{
        position: absolute;
    }
    .product_layout_n {
        float: none;
        width: 97%;
        margin: 0 0 5px 0;
        padding: 11px 1px 5px 7px;
    }
    .total_btm-bar {
        width: 100%;
    }
    .product_contents_n {
    padding: 26px 0 0 11px !important;
}
    .cart-page {
        padding: 0 0 0 8px;
    }
    #product_row p {
    font-size: 13px;
}
    .main_body {
        height: 100%;
    }
    .form_box a {
        font-size: 22px;
    }
    .bottom_bar ul li b {
        font-size: 13px;
    }
    .language_btn a{
        font-size:18px;
    }
    .delivery_address {
      width: 100%;
    }
    .delivery_address_8{
      width: 55%;
    }
    .delivery_address_4 {
      width: 44%;
    }
   .product_price_n p {
        font-size: 12px !important;
    }
    .loader img {
        margin: 67% auto;
        width: auto ;
    }
    .product_price_n .value_div {
        padding: 7px 9px 6px;
        font-size: 12px;
        height: 29px;
        vertical-align: middle;
        width: 25px;
        border-radius: 0;
    }
    .product_contents_n h4 {
        font-size: 12px;
    }
    .login_screen, .logo_screen_n {
        margin: 0 auto 0;
    }
    .contact_page .logo img {
        margin: 40px auto 14px;
    }
    .contact_page .Select_language {
        padding: 11px 14px 0;
    }
    .request_door_click span {
        margin: -2px 1px 0 0;
    }
    .quantity_box .minus_plus, .quantity_box .value_box {
        border-radius: 0 !important;
    }
    .cata-sub-slider {
        width: 82%;
    }
    }
@media print {
   body {
      -webkit-print-color-adjust: exact;
   }
   
}
